import React, { ReactNode } from 'react';

export interface IFooterComponent {
    children: any,
}

const FooterComponent: React.FC<IFooterComponent> = (props) => {
    
    return (
        <div className='bg-violet-400 w-full flex flex-row justify-between p-2 fixed bottom-0'>
           {props.children}
        </div>
    )
}

export default FooterComponent;