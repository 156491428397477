import React, { ReactNode } from 'react';

export interface IHeaderComponent {
    children: any,
}

const HeaderComponent: React.FC<IHeaderComponent> = (props) => {
    
    return (
        <div className='bg-violet-400 w-full flex flex-row justify-between py-2 fixed top-0'>
           {props.children}
      </div>
    )
}

export default HeaderComponent;