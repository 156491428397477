import React, { useEffect, useState } from 'react';
import './App.css';
import APIService from './services/APIService';
import { Setting } from './models/Setting';
import DifficultyChooserComponent from './components/DifficultyChooserComponent';
import FooterComponent from './components/FooterComponent';
import HeaderComponent from './components/HeaderComponent';
import { delay } from './utils/CommonFunctions';
import QuestionComponent from './components/QuestionComponent';
import { GiBabyBottle } from 'react-icons/gi';

const App  = () => {
  const apiService: APIService = new APIService();
  const [appSettings, setAppSettings] = useState<Array<Setting> | undefined>();
  const [chosenDifficulty, setChosenDifficulty] = useState<number | undefined>();
  const [requiredCorrectQuestions, setRequiredCorrectQuestions] = useState<number>(0);
  const [correctQuestions, setCorrectQuestions] = useState<number>(0);
  const [showDifficultyChooser, setShowDifficultyChooser] = useState(false);
  const [showAnimationOut, setShowAnimationOut] = useState(false);
  const [showGame, setShowGame] = useState(false);
  const [secret, setSecret] = useState("");

  useEffect(() => {
    console.log("Yolo");
    const getRandomQuestion = async(): Promise<void> => {
      const randomQuestionResult = await apiService.GetRandomQuestion();
      console.log(randomQuestionResult);
    }

    const getAppSettings = async(): Promise<void> => {
      const settings = await apiService.GetSettings();
      console.log("GotAppsettings");
      console.log(settings);
      setAppSettings(settings.settings);
    }

    getRandomQuestion();
    getAppSettings();
    
  }, []);

  useEffect(() => {
    console.log("AppSettings: ");
    console.log(appSettings);
    console.log();
    if(requiredCorrectQuestions === 0) {
      if((appSettings?.filter((v) => v.name === "HardModeEnabled")[0])?.value === "false") {
        setRequiredCorrectQuestions(-1);
      } else {
        setRequiredCorrectQuestions(parseInt((appSettings?.filter((v) => v.name === "RequiredCorrectAnswers")[0])?.value || "0"));
      }
    }
    if(secret === "") {
      setSecret((appSettings?.filter((v) => v.name === "Secret")[0])?.value || "");
    }
    setShowDifficultyChooser(true);
  }, [appSettings]);

  const fadeOutDifficulty = async (id: number) => {
    setShowAnimationOut(true);
    await delay(500);
    setShowAnimationOut(false);
    setShowDifficultyChooser(false);
    setChosenDifficulty(id);
    setShowGame(true);
  }

  const restart = async() => {
    setShowAnimationOut(true);
    await delay(500);
    setShowAnimationOut(false);
    setChosenDifficulty(undefined);
    setShowGame(false);
    setShowDifficultyChooser(true);
  }

  return (
    <div className="App bg-violet-200 flex flex-col h-screen v-screen items-center justify-center">
      <HeaderComponent>
        <p className="text-white min-w-[33%]">{chosenDifficulty === 1 && "Easy"}{chosenDifficulty === 2 && "Medium"}{chosenDifficulty === 3 && "Hard"}</p>
        <div className="text-white min-w-[33%] font-semibold">Baby Trivia</div>
        <div className="min-w-[33%] grid justify-items-end">
          <p className="text-white mr-2">{correctQuestions}/{(requiredCorrectQuestions >=0 ) ? requiredCorrectQuestions : <>&infin;</>}</p>
        </div>
      </HeaderComponent>
      {showDifficultyChooser && appSettings != undefined &&
        <DifficultyChooserComponent appSettings={appSettings} onClick={fadeOutDifficulty} animateOut={showAnimationOut} />
      }
      {showGame &&
      <QuestionComponent animateOut={showAnimationOut} apiService={apiService} difficulty={chosenDifficulty || 1} requiredCorrectAnswers={requiredCorrectQuestions} setCorrectAnswers={setCorrectQuestions} restart={restart} secret={secret} />

      }
      <FooterComponent>
        <div className="flex place-items-center text-[10px] text-violet-200 italic"><span className="mr-0.5">Will you be the first to discover the secret?</span> <GiBabyBottle /></div>
      </FooterComponent>
    </div>
  );
}

export default App;
