import { Option } from "./Options";

export class Question {
    id?: number;
    difficulty?: number;
    shortDescription?: string;
    wording?: string;
    options?: Array<Option>;

    constructor() {
        this.options = new Array<Option>();
    }
}