const globals = {
    BASE_API_URL: 'https://api-netcore.chattdroid.com/api/trivia/game/',
    //BASE_API_URL: 'https://localhost:7057/api/trivia/game/',
    MESSAGES: {
        UNKNOWN_ERROR: 'An Unknown Error Has Occurred. Please Try again later.',
        INVALID_USERNAME_OR_PASSWORD: 'Invalid Username or Password',
        TOKEN_EXPIRED: 'Token Expired: Please Login again.',
        TOKEN_INVALID: 'Token Invalid: Please Login again.',
        LOGGING_IN: 'Logging in Please wait',
        LOADING_HANG_ON: 'Loading hang on a second',
        IDLE_LOGOUT: 'Logged out because idle.',
        PERMISSIONS_DENIED: 'You do not have access to that page.'
      },
      CODES: {
        NETWORK_ERROR: 525,
        TOKEN_EXPIRED: 527,
        TYPE_ERROR: 530,
        CONFLICT_ERROR: 409
      },
};

export default globals;