import React, { ReactNode, useEffect, useState } from 'react';
import { Setting } from '../models/Setting';
import APIService from '../services/APIService';
import { Question } from '../models/Question';
import { ImCheckmark, ImCross } from 'react-icons/im'
import { delay } from '../utils/CommonFunctions';
import { Prize } from '../models/Prize';
import ConfettiExplosion from 'react-confetti-explosion';

export interface IQuestionComponent {
    animateOut: boolean;
    apiService: APIService;
    difficulty: number;
    setCorrectAnswers: (correct: number) => void;
    restart: () => void;
    requiredCorrectAnswers: number;
    secret: string;
}

const QuestionComponent: React.FC<IQuestionComponent> = (props) => {
    const [answeredQuestions, setAnsweredQuestions] = useState<Array<number>>(new Array<number>);
    const [currentQuestion, setCurrentQuestion] = useState<Question>(new Question);
    const [correctAnswer, setCorrectAnswer] = useState<boolean>(false);
    const [incorrectAnswer, setInCorrectAnswer] = useState<boolean>(false);
    const [completedGame, setCompletedGame] = useState<boolean>(false);
    const [prize, setPrize] = useState<Prize | undefined>(undefined);
    const [showGame, setShowGame] = useState<boolean>(true);
    const [showPrize, setShowPrize] = useState<boolean>(false);
    const [showRealPrize, setShowRealPrize] = useState<boolean>(false);
    const [showFakePrize, setShowFakePrize] = useState<boolean>(false);
    const [isExploding, setIsExploding] = React.useState(false);
    
    useEffect(() => {
        getQuestionAsync();
    }, []);

    const clickAnswer = async(answer: boolean) => {
        if(answer) {
            setCorrectAnswer(true);
            const _answeredQuestions = [...answeredQuestions];
            _answeredQuestions.push(currentQuestion.id || 0);
            props.setCorrectAnswers(_answeredQuestions.length);
            setAnsweredQuestions(_answeredQuestions);
            if(_answeredQuestions.length >= props.requiredCorrectAnswers) {
                setCompletedGame(true);
            }
        } else {
            setInCorrectAnswer(true);
        }
    }

    const getQuestionAsync = async(): Promise<void> => {
        setInCorrectAnswer(false);
        let question = await props.apiService.GetNextQuestion(props.difficulty, answeredQuestions);
        console.log("Question");
        console.log(question.question?.shortDescription);
        if(question?.question?.options !== undefined) {
            question.question.options = question.question?.options?.sort(() => Math.random() > .5 ? 1 : -1);
        }
        
        setCurrentQuestion(question.question || new Question);

        setCorrectAnswer(false);
    };

    const Retry = async(): Promise<void> => {
        setCorrectAnswer(false);
        setInCorrectAnswer(false);
        setAnsweredQuestions(new Array<number>);
        props.setCorrectAnswers(0);
        await props.restart();
        setCurrentQuestion(new Question);
    }

    const GetPrize = async(): Promise<void> => {
        console.log("Get Prize");
        let prize = await props.apiService.GetPrize(props.secret);
        localStorage.setItem("completed", "true");
        setPrize(prize.prize);
        await delay(500);
        setShowGame(false);
        setShowPrize(true);
        setShowFakePrize(true);
        await delay(6000);
        setShowRealPrize(true);
        setShowFakePrize(false);
        await delay(6000);
        setIsExploding(true);
    }
    
    return (
        <div className={'bg-violet-400 flex flex-col items-center rounded-lg p-12 animate-fadeIn ' + (props.animateOut ? ' animate-fadeOut ' : '')}>
            {showGame &&
            <div className={"game " + (prize !== undefined ? 'animate-fadeOut': '')}>
                <div className="questionImage items-center mb-2 flex justify-center"><img src={`${currentQuestion.shortDescription}.jpg`}></img></div>
                <div className="mb-4 font-semibold text-white">{currentQuestion.wording}</div>
                
                <div className="questionOptions relative min-w-full">
                    {
                        currentQuestion.options?.map((m) => {
                            return (
                                <div key={m.id} className="text-xl min-w-full font-bold text-gray-100 rounded-lg bg-violet-600 w-full my-2 p-2 border-2 border-violet-700 hover:bg-violet-700 hover:border-violet-600 cursor-pointer drop-shadow-sm"
                                onClick={() => clickAnswer(m.answer || false)}>
                                    {m.wording} 
                                </div>
                            );
                        })
                    }
                {(correctAnswer || incorrectAnswer) &&
                    <div className="z-10 absolute inset-0 flex justify-center items-center bg-gray-500 border-2 border-gray-700 rounded-lg bg-opacity-50 animate-fadeIn0_5x">
                        {correctAnswer &&
                        <div className="z-20 absolute bg-gray-200 drop-shadow-lg rounded-2xl p-4">
                            <ImCheckmark className='text-green-600 text-5xl font-bold' />
                        </div>
                        }
                        {incorrectAnswer &&
                        <div className="z-20 absolute bg-gray-200 drop-shadow-lg rounded-2xl p-4">
                            <ImCross className='text-red-600 text-5xl font-bold' />
                        </div>
                        }
                    </div>
                }
                </div>
            </div>
            }
            {showPrize &&
                <>
                    {showFakePrize &&
                    <div className={"fakePrize " + (showRealPrize ? ' animate-fadeOut ' : "")}>
                        <div className="animate-fadeIn flex"><div>Your Prize: </div> <div className="ml-1 animate-fadeInEX2x">It's a Baby!</div></div>
                        <div className="animate-fadeInEX4x">JK</div>
                    </div>
                    }
                    {showRealPrize &&
                        <div className="animate-fadeInEX2x">Your Real Prize: <div className="ml-1 animate-fadeInEX4x flex">It's a <div className={`ml-1 animate-fadeInEX6x font-bold ${prize?.detail === "boy" ? 'text-blue-200' : 'text-pink-300'}`}>{prize?.detail}!</div>
                            {isExploding && <ConfettiExplosion duration={4400} particleCount={300} colors={[(prize?.detail === "boy" ? '#60a5fa' : '#f9a8d4')]} />}
                        </div></div>
                    }
                </>
            }
            {!showPrize && correctAnswer === false && incorrectAnswer === false && completedGame === false &&
                <div className="p-2 mt-3 border-2 border-violet-400"><h1>&nbsp;</h1></div>
            }
            {!showPrize && correctAnswer === true && incorrectAnswer === false && completedGame === false &&
                <div className="text-white mt-3 p-2 rounded-xl cursor-pointer drop-shadow-sm border-2 border-violet-700 bg-gradient-to-r from-blue-400 to-pink-400 animate-fadeIn0_5x" onClick={() => getQuestionAsync()}><h1 className="outlineText">Next Question</h1></div>
            }
            {!showPrize && incorrectAnswer === true && correctAnswer === false && completedGame === false &&
                <div className="text-white mt-3 p-2 rounded-xl cursor-pointer drop-shadow-sm border-2 border-violet-700 bg-gradient-to-r from-blue-400 to-pink-400 animate-fadeIn0_5x" onClick={() => Retry()}><h1 className="outlineText">Retry</h1></div>
            }
            {!showPrize && completedGame === true &&
                <div className="text-white mt-3 p-2 rounded-xl cursor-pointer drop-shadow-sm border-2 border-violet-700 bg-gradient-to-r from-blue-400 to-pink-400 animate-fadeIn0_5x" onClick={() => GetPrize()}><h1 className="outlineText">Get Prize</h1></div>
            }
      </div>
    )
}

export default QuestionComponent;