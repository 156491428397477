import React, { ReactNode } from 'react';
import { Setting } from '../models/Setting';

export interface IDifficultyChooser {
    onClick:(id: number) => void;
    appSettings: Setting[];
    animateOut: boolean;
}

const DifficultyChooserComponent: React.FC<IDifficultyChooser> = (props) => {
    const _completed = localStorage.getItem("completed");
    let completed = false;
    if(_completed === "true")
    completed = true;

    
    return (
        <div className={'bg-violet-400 flex flex-col items-center rounded-lg p-12 ' + (props.animateOut ? ' animate-fadeOut ' : '')}>
            <h1 className="text-white text-2xl mb-8 font-bold">Choose your difficulty</h1>
            {
            props.appSettings.map((m) => {
                if(m.name === "Difficulty") {
                return (
                    <div key={m.id} className="text-3xl font-bold text-gray-100 rounded-lg bg-violet-600 w-full m-2 p-2 border-2 border-violet-700 hover:bg-violet-700 hover:border-violet-600 cursor-pointer drop-shadow-sm"
                    onClick={() => props.onClick(m.id || 0)}>
                        {m.description}
                    </div>
                )
                }
                return;
            })
            }
            {completed &&
            <div className="text-3xl font-bold text-gray-100 rounded-lg bg-sand w-full m-2 p-2 border-2 border-sand-200 hover:bg-sand-200 hover:border-sand-200 cursor-pointer drop-shadow-sm"
            onClick={() => props.onClick(10)}>
                Hondo
            </div>
            }
      </div>
    )
}

export default DifficultyChooserComponent;